//@author: sara

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-security-link")
@Component({
    selector: "s25-security-link",
    template: `
        <a
            [href]="this.securityLink | safeLink"
            target="_blank"
            class="ngInlineBlock ngOptElementPad c-textButton ng-scope"
        >
            <svg class="c-svgIcon c-svgIcon--medium" role="img">
                <title>View & Edit Security Icon</title>
                <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#lock"></use>
            </svg>
            Security
        </a>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25SecurityLinkComponent implements OnInit, OnChanges {
    @Input() itemTypeId: number;
    @Input() itemId: number;
    @Input() groupAttr?: any;
    @Input() nodeType?: "F"; //if viewing/editing security in Groups for a folder, comes through as an event, so need to avoid using node_type=E in url
    @Input() query: boolean;
    @Input() itemType?: "system_tags"; //items that don't have standard ids or follow a different pattern here.

    constructor() {}
    init = false;
    securityLink = "";
    baseUrl: String = S25Util.getURL("", "/groups");
    typeIdtoIdType: { [key: number]: string } = {
        4: "space_id",
        2: "organization_id",
        6: "resource_id",
        1: "event_id",
    };
    typeIdtoItem: { [key: number]: string } = {
        4: "location",
        2: "organization",
        6: "resource",
        1: "event",
    };

    getSecurityUrl(objectTypeId: number, objectId: number, query?: boolean) {
        let groupQueryVal;
        //https://25live.collegenet.com/psuqa/groups/#/location/multi/multi_pivot/ols?group_id=all&space_id=75
        //https://25live.collegenet.com/belmont/groups/#/organization/multi/organization_multi_pivot/ols?group_id=all&organization_id=1366
        //https://25live.collegenet.com/belmont/groups/#/resource/multi/resource_multi_pivot/ols?group_id=all&resource_id=7
        //http://localhost/burnside/groups/#/system_tags/multi/pivot?group_id=all&tag_id=15

        if (this.itemType === "system_tags") {
            return this.baseUrl + "/#/" + "system_tags" + "/multi/pivot?group_id=all&tag_id=" + objectId.toString();
        }

        if (this.groupAttr) {
            //when Your Starred {Object Type} selected, have to replace &{object type}_favorite=T with &favorite=T
            const adjustFavQuery = this.groupAttr.itemName && this.groupAttr.itemName.slice(0, 12) === "Your Starred";

            if (adjustFavQuery) {
                groupQueryVal = "&favorite=T";
            } else if (this.groupAttr.queryVal === "&spaces_direct=T&spaces_min_ols=R&spaces_can_assign=T") {
                groupQueryVal = "&direct=T";
            } else {
                groupQueryVal = this.groupAttr.queryVal;
            }
        }

        let objectType = this.typeIdtoItem[objectTypeId];
        let idType = query ? "&query_id" : `&${this.typeIdtoIdType[objectTypeId]}`;
        let subRoute = objectTypeId === 4 ? "" : objectType + "_";
        let idVal = objectId ? `${idType}=${objectId.toString()}` : groupQueryVal;
        let eventNode = objectType === "event" && this.nodeType !== "F" ? "&node_type=E" : "";

        return (
            this.baseUrl +
            "/#/" +
            objectType +
            "/multi/" +
            subRoute +
            "multi_pivot/ols?group_id=all" +
            idVal +
            eventNode
        );
    }
    ngOnInit() {
        this.securityLink = this.getSecurityUrl(this.itemTypeId, this.itemId, this.query);
        this.init = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ngOnInit();
    }
}
