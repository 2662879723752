import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { LynxService } from "../../services/lynx.service";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { ContactService } from "../../services/contact.service";
import { S25Util } from "../../util/s25-util";

@TypeManagerDecorator("s25-ng-lynx-users")
@Component({
    selector: "s25-ng-lynx-users",
    template: `
        <p>
            This is an experimental interface to create a lynx user for a selected lynx instance reach out to 25live dev
            team with any problems
        </p>
        <select [(ngModel)]="lynxInstance" class="cn-form__control c-margin-top--half">
            <option [value]="''" selected disabled hidden>Select a Lynx Instance</option>
            @for (instance of lynxInstances; track instance) {
                <option [value]="instance.name">{{ instance.name }}</option>
            }
        </select>
        <s25-contact-dropdown
            [r25UserOnly]="true"
            [chosen]="chosenUser"
            (chosenChange)="setUserDetails($event)"
            class="c-margin-bottom--single"
        ></s25-contact-dropdown>
        <div class="c-margin-bottom--half">
            <label for="newPassword">Lynx Password - Optional</label>
            <input
                type="password"
                [(ngModel)]="chosenUser.password"
                class="c-input"
                id="newPassword"
                [maxLength]="20"
            />
            <button class="aw-button aw-button--outline" (click)="generateRandomPassword()">Generate Random</button>
        </div>
        <s25-ng-button
            [type]="'primary'"
            (click)="createUser()"
            [disabled]="lynxInstances.length === 0"
            [disabledReason]="'This 25live instance does not have LYNX configured'"
        >
            Create user {{ chosenUser.username || "" }} on {{ lynxInstance || "" }}
        </s25-ng-button>
        @if (saveMsg) {
            <p class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single c-margin-top--single">
                {{ saveMsg }}
            </p>
        } @else if (errorMsg) {
            <p class="ngRed ngBold cn-alert cn-alert--error c-margin-bottom--single c-margin-top--single">
                {{ errorMsg }}
            </p>
        }
    `,
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25LynxUsersComponent implements OnInit {
    chosenUser: S25ItemI = {};
    lynxInstance: string = "";
    lynxInstances: { name: string }[] = [];
    saveMsg: string = "";
    errorMsg: string = "";

    reset() {
        this.saveMsg = "";
        this.errorMsg = "";
    }

    async createUser() {
        this.reset();
        if (!this.chosenUser.username || !this.lynxInstance || !this.chosenUser.itemDesc) {
            this.saveMsg = "Please fill out all fields";
            this.cd.detectChanges();
            return;
        }
        const [resp, error] = await S25Util.Maybe(
            LynxService.createUser(
                this.chosenUser.username,
                this.lynxInstance,
                this.chosenUser.itemDesc,
                this.chosenUser.password,
            ),
        );

        if (error) {
            this.errorMsg = error || "Problem creating user";
        } else {
            this.saveMsg = "Successfully created!";
        }
        this.cd.detectChanges();
    }

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        //getInstances inlcludes _lynx on each name. We don't want that
        this.lynxInstances = await LynxService.getInstances();
        this.lynxInstances.forEach((inst) => {
            inst.name = inst.name.replace("_lynx", "");
        });
        this.cd.detectChanges();
    }

    async setUserDetails(cont: S25ItemI) {
        this.chosenUser.username = await ContactService.getContactUsername(cont.itemId as number);
        this.chosenUser.itemDesc = cont.itemDesc;
        this.cd.detectChanges();
    }

    //Almost always user logs in using 25live session. Password is only used for lynx WS integrations. But lynx requires one so we make one up
    generateRandomPassword(length = 16) {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let randomPassword = "";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            randomPassword += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.chosenUser.password = randomPassword;
        this.reset();
        this.cd.detectChanges();
    }
}
